import React, {Component} from 'react';
import moment from 'moment';
import * as Inflected from 'inflected';

export default class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {initialTime: secondsNow()};
    this.calculate = this.calculate.bind(this);
  }

  componentDidMount() {
    this.calculate();
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  calculate() {
    let {remaining, onComplete} = this.props;
    let {initialTime} = this.state;
    let delta = secondsNow() - initialTime;
    let distance = calculateDistance(remaining - delta);
    if (distance.value > 0) {
      this.setState({value: distance.value, unit: distance.mode});
      this.timeoutId = setTimeout(this.calculate, distance.remaining * 1000);
    } else {
      this.timeoutId = null;
      onComplete();
    }
  }

  render() {
    let {value, unit} = this.state;
    let {text} = this.props;

    return (
      <div className="countdown">
        {text} <span className="countdown-value">{value}</span> <span className="coutndown-unit">{unit}</span>.
      </div>
    );
  }
}

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

function secondsNow() {
  return Math.round(Date.now() / 1000);
}

function calculateDistance(seconds) {
  let days = Math.floor(seconds / DAY);
  if ( days > 0) {
    let remaining = seconds % DAY;
    return {value: days, mode: 'days', remaining: Math.ceil(remaining)};
  }

  let hours = Math.floor(seconds / HOUR);
  if (hours > 0) {
    let remaining = seconds % HOUR;
    return {value: hours, mode: 'hours', remaining: Math.ceil(remaining)};
  }

  let minutes = Math.ceil(seconds / MINUTE);
  if (minutes > 1) {
    let remaining = seconds % MINUTE;
    return {value: minutes, mode: 'minutes', remaining: Math.ceil(remaining)};
  }

  return {value: Math.floor(seconds),  mode: 'seconds', remaining: 1}
}

import React, {Component} from 'react';
import Markdown from './markdown';
import Request from '../request';
import Comments from './comments';
import Countdown from './countdown';
import moment from 'moment';
// import {DoughnutChart} from './charts';
import {basketballImage, smallWhiteLogo} from '../constants.js.erb';

const AFTER_SUBMIT_DELAY = 2760;

export default class Poll extends Component {
  constructor(props) {
    super(props);
    let {poll, voted_option_id, voted_option_comment} = props;
    this.state = {poll};

    if (voted_option_id) {
      this.state.voted_option = poll.options.find(o => o.id === voted_option_id);
    }
    if (voted_option_comment && voted_option_comment.length > 0) {
      this.state.comment = voted_option_comment;
    }

    this.onChangeComment = this.onChangeComment.bind(this);
    this.submit = this.submit.bind(this);

    /*
     * OPTIONS FOR DEVELOPMENT
     *
     * To show the voting view:
     * http://localhost:3000/polls/test_1?voting=1
     *
     * To show the voted view with the second option selected
     * http://localhost:3000/polls/test_1?voted_option_index=1
     */

    let url = new URL(window.location.href);
    let voting = url.searchParams.get("voting") == 1

    if (voting) {
      this.state.voting = true
    }

    let voted_option_index = url.searchParams.get("voted_option_index")
    if (voted_option_index) {
      this.state.voted_option = this.props.poll.options[voted_option_index]
    }
  }

  onChangeComment(e) {
    let {value} = e.target;
    this.setState({comment: value});
  }

  vote(option) {
    this.setState({selected_option_id: option.id});
  }

  submit() {
    let {poll} = this.props;
    let {comment, selected_option_id} = this.state;
    if (!selected_option_id) return;

    let startVotingTS = Date.now();
    this.setState({voting: true});

    let url = `/polls/${poll.id}/vote`;

    Request('POST',url, {option_id: selected_option_id, comment})
      .then(poll => {
        let voted_option = poll.options.find(o => o.id === selected_option_id);
        this.setState({poll, voted_option});

        // scroll to top
        window.scrollTo(0,0);

        // Force the delay
        let delta = AFTER_SUBMIT_DELAY - (Date.now() - startVotingTS);
        delay(delta).then(() => this.setState({voting: false}));
      })
      .catch(error => {
        this.setState({voting: false});
      });
  }

  renderOption(option) {
    let {selected_option_id} = this.state;
    let className = "poll-option clickable d-flex align-items-center";
    if (selected_option_id === option.id) {
      className += " selected";
    }

    return (
      <div key={option.id} className={className} onClick={e => this.vote(option)} >
        <div className="poll-option-icon"><i className="fa fa-check"></i></div>
        <div className="poll-option-container">
          <p><b>{option.chart_label}</b></p>
          <span className="poll-option-text">
            <Markdown text={option.text} />
          </span>
        </div>
      </div>
    )
  }

  renderVoting() {
    return(
      <div id="voting" className="d-flex justify-content-center my-5">
        <div className="poll-container d-flex flex-column">
          {this.renderPollStatus()}
          <div className="d-flex flex-column align-items-center my-5">
            <img className="basketball-loader" src={basketballImage} />
            <h1>Recording Your Take.</h1>
            <p>Let's see how you stack up...</p>
          </div>
        </div>
      </div>
    );
  }

  renderAsOfText() {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let now = new Date();
    let month = months[now.getMonth()];
    let day = now.getDate();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + ampm;
    return (
      <p>As of {month} {day} • {strTime}</p>
    )
  }

  renderTakeCard() {
    let {user_name} = this.props;
    let {poll, comment, voted_option} = this.state;

    if (!voted_option) return null
    let total_votes = poll.options.reduce((total, option) => total + option.votes_count, 0);
    let voteRatio = voted_option.votes_count / total_votes
    let displayPercentage = Math.round(voteRatio * 100);


    let display_comment = comment || voted_option.text;
    if (display_comment.length > 186) {
      display_comment = display_comment.slice(0, 186);
    }
    let declarative_statement = voted_option.declarative_statement || voted_option.chart_label;
    return (
      <div className="share-card">
        <div className="share-card-inner">
          <span className="my-take-label">MY TAKE</span>

          <h1 className="my-take-headline">{declarative_statement}</h1>
          <div className="share-card-image-mask">
            <img src={voted_option.image_medium} className="share-card-image" />
          </div>
          <div className="share-card-comment">
            <p className="share-card-user">@{user_name}</p>
            <p>{display_comment}</p>
          </div>
          <div className="share-card-details">
            <div className="as-of-details">
              {this.renderAsOfText()}
            </div>
            <div className="percentage-holder">
              <span className="percentage-ball">{displayPercentage}<span className="percentage-symbol">%</span></span>
              <span className="percentage-text">AGREE WITH ME</span>
            </div>
            <div className="time-left-details">
              {poll.is_open ?
                <Countdown cardStyle remaining={poll.remaining_time} text="Final results in" onComplete={() => location.reload()} /> :
                <p>This Poll is Closed.</p>}
            </div>
          </div>
        </div>
        <div className="share-card-footer">
          <p>VOTE @ <a href="/">ThePeoplesTake.com</a></p>
          <img src={smallWhiteLogo} />
        </div>

      </div>
    )
  }


  renderVoted() {
    let {user_id} = this.props;
    let {poll} = this.state;
    // let most_voted_option = poll.options.sort(function(a, b){return b.votes_count - a.votes_count})[0]

    return (
      <>
      {this.renderTakeCard()}
      {this.renderPollResults()}
      <div id="voting_completed" className="d-flex justify-content-center mb-5">
        <div className="poll-container d-flex flex-column">
          {/* {this.renderPollStatus()} */}
          {/* <Markdown text={poll.question} className="poll-result-question" />
          <Markdown text={poll.description} className="poll-result-description" /> */}

          {/* {this.renderPollDetails(most_voted_option)} */}
          {/* {this.renderMostVoted(most_voted_option)} */}

          {/* <div className="d-flex justify-content-center">
            <div id="chart_container">
              <DoughnutChart poll={poll} />
            </div>
          </div> */}
          {/* {this.renderUrgentMessage()} */}
          {this.renderShareButton()}
          {this.renderCopyUrltoClipBoardButton()}

          <Comments poll={poll} user_id={user_id} />
        </div>
      </div>
      </>
    )
  }

  renderOptionResult(option) {
    let {poll} = this.state;

    let total_votes = poll.options.reduce((total, option) => total + option.votes_count, 0);
    let voteRatio = option.votes_count / total_votes
    let displayPercentage = Math.round(voteRatio * 100);
    let graphWidth = displayPercentage + '%'

    return (
      <div className="results-table-item d-flex align-items-center">
        <img src={option.image_small} />
        <div className="results-table-item-details">
          <div>
            <p className="results-table-item-text">{option.chart_label}</p>
            <p className="results-table-item-percentage">{displayPercentage}%</p>
          </div>
          <div className="percentage-bar" style={{width: graphWidth}} ></div>
       </div>
      </div>
    )
  }

  renderPollResults() {
    let {poll} = this.state;
    let sorted_options = poll.options.sort(function(a, b){return b.votes_count - a.votes_count})
    let date = moment(poll.end_date).format('LL');
    return (
      <div className="results-table-holder">
        <div className="results-table">
          <p className="results-headline">
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <span>THE PEOPLE SAY</span>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
          </p>
          <h2 className="results-poll-question"><Markdown text={poll.question} /></h2>
          {sorted_options.map(o => this.renderOptionResult(o))}
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
            {this.renderAsOfText()}
            {poll.is_open ?
            <b><Countdown cardStyle remaining={poll.remaining_time} text="Final results in" onComplete={() => location.reload()} /></b> :
            <p><b>Poll closed {date}.</b></p>}
          </div>
        </div>
      </div>
    )
  }



  // renderPollDetails(most_voted_option) {
  //   let {voted_option} = this.state;
  //   let chartHeadline, imageSource, text, headerText;
  //   if (!voted_option) {
  //     headerText = "The People Voted"
  //     imageSource = most_voted_option.image_medium
  //     text = most_voted_option.text
  //     chartHeadline = "The People Say"
  //   } else if (voted_option.id === most_voted_option.id) {
  //     headerText = `You Voted: ${voted_option.chart_label}`
  //     imageSource = most_voted_option.image_medium
  //     text = most_voted_option.text
  //     chartHeadline = "The People Agree"
  //   } else {
  //     headerText = `You Voted: ${voted_option.chart_label}`
  //     imageSource = voted_option.image_medium
  //     text = voted_option.text
  //     chartHeadline = ""
  //   }
  //   return (
  //     <>
  //       <p className="voted-header"><b>{headerText}</b></p>
  //       <img src={imageSource} className="poll-image" />
  //       <Markdown text={text} className="voted-headline-option text-center" />
  //       <h2 className="voted-headline-lead text-center">{chartHeadline}</h2>
  //     </>
  //   )
  // }

  // renderMostVoted(most_voted_option) {
  //   let {voted_option} = this.state;
  //   if (!voted_option) return null
  //   if (voted_option.id === most_voted_option.id) return null
  //   return (
  //     <div className="d-flex my-3 most-voted-highlight align-items-center align-items-sm-start flex-column flex-sm-row">
  //       <div className="text-center text-sm-start">
  //         <h2 className="voted-headline-lead">The people say:</h2>
  //         <Markdown text={most_voted_option.text} className="voted-headline-option"/>
  //       </div>
  //       <img src={most_voted_option.image_medium} className="mb-3 mx-3" />
  //     </div>
  //   )
  // }


  renderShareButton() {
    let {poll} = this.state;
    let truncatedText = poll.question_text.length > 100 ? poll.question_text.substring(0, 97) + "" : poll.question_text;


    let text = `${truncatedText} %0A%0AWhat's your take? %0A@peoplestakenba %0A%0A`;
    let url = window.location.href;
    let link = `https://twitter.com/intent/tweet?text=${text}&url=${url}`

    return (
      <div className="share-button-holder">
        <a href={link} className="outline-button share-button" target="_blank" >Share On X (Twitter)</a>
      </div>
    )
  }

  renderCopyUrltoClipBoardButton() {
    let text = window.location.href;

    return (
      <div className="mt-2 mb-5 copy-url-button">
        <button onClick={() => {navigator.clipboard.writeText(window.location.href)}}><i className="fa fa-share"></i> Copy Link to Share</button>
      </div>
    )
  }

  renderPoll() {
    let {poll} = this.state;

    return (
      <div id="poll" className="d-flex justify-content-center my-5">
        <div className="poll-container d-flex flex-column">
          {this.renderPollStatus()}
          <img src={poll.image_medium} className="poll-image" />
          <div className="poll-question-container">
            <Markdown text={poll.question} />
          </div>
          <Markdown text={poll.description} className="poll-description-container"/>


          <div id="options" className="mt-4">
            {poll.options.map(o => this.renderOption(o))}
          </div>

          {this.renderPollForm()}
        </div>
      </div>
    )
  }


  renderPollForm() {
    let {user_id} = this.props;
    if (!user_id) return this.renderSignInButton();
    let {comment} = this.state;
    return (
      <form id="comment-form" className="d-flex flex-column mt-5 mb-2" onSubmit={this.submit}>
        <label>Add a Comment</label>
        <p className="comment-form-desc">Impress the people with your insight, earn upvotes.</p>
        <textarea value={comment} onChange={this.onChangeComment} placeholder="What's your take?" />
        <button className="btn-vote" onClick={this.submit} >SUBMIT</button>
      </form>
    )
  }

  // renderUrgentMessage() {
  //   let {poll, voted_option} = this.state;
  //   let {user_id} = this.props;
  //   if (!poll.is_open || !voted_option || !user_id) return null;

  //   return (
  //     <div className='mb-2'>
  //       <div className="poll-closing-share-message">
  //         <Countdown remaining={poll.remaining_time} text="Final results in" onComplete={() => location.reload()} />
  //         Spread the word. It's not over until it's over.
  //       </div>
  //     </div>
  //   )
  // }




  renderPollStatus() {
    let {poll} = this.state;
    let {user_id} = this.props;
    if (poll.is_open) return this.renderTimeUntilClose();
    let url = '/polls';
    let link = ''
    if (user_id) {
      link = <a href='/polls' className="white-link">See Active Polls</a>
    }
    let date = moment(poll.start_date).format('LL');

    return (
      <div className='mb-3'>
        <p className="closed-poll-date">{date}</p>
        <p className="closed-poll-message d-flex justify-content-between">
          <span>This Poll is Closed.</span>
          <span>{link}</span>
        </p>
      </div>
    )
  }

  renderTimeUntilClose() {
    let {poll, voted_option} = this.state;
    if (voted_option) return null;

    return (
      <div className="polling-closing-message">
        <Countdown remaining={poll.remaining_time} text="This poll closes in" onComplete={() => location.reload()} />
      </div>
    )
  }

  renderSignInButton() {
    let return_path = window.location.pathname;
    let url = '/?return_path=' + return_path;
    let {poll} = this.state;
    let buttonText;
    if (poll.is_open)
      buttonText = "Sign In to Vote"
    else {
      buttonText = "Sign In to See Results"
    }
    return (
      <div className="mt-5 d-flex flex-column align-items-stretch justify-content-center">
        {this.renderPollStatus()}
        <a href={url} className="btn btn-signin">{buttonText}</a>
        <p className="text-center mt-3">Be heard and see what the people say.</p>
      </div>
    )
  }


  render() {
    let {poll, voting, voted_option} = this.state;
    let {user_id} = this.props;

    if (voting) return this.renderVoting();

    if (!user_id) return this.renderPoll();
      console.log(voted_option)
    if (voted_option || (!poll.is_open && user_id) ) return this.renderVoted();
    return this.renderPoll();
  }
}


function delay(delta) {
  if (delta < 0) return Promise.resolve();
  return new Promise(success => {
    setTimeout(success, delta);
  });
}

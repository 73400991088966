import React, {Component} from 'react';

import Chart from 'chart.js/auto';

class BaseChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.initChartEl = this.initChartEl.bind(this);
  }


  initChartEl(el) {
    if (el && !this.chart) {
      this.chart = new Chart(el, this.getConfig());
    }
  }

  render() {
    let {id, className} = this.props;
    return (
      <canvas id={id} className={className} ref={this.initChartEl}></canvas>
    );
  }
}


export class DoughnutChart extends BaseChart {
  getConfig() {
    let {data, title, poll} = this.props;

    let poll_data = poll ? buildChartData(poll) : data;
    return {
      type: 'doughnut',
      data: poll_data,
      options: {
        animateScale: true,
        responsive: true,
        plugins: {
          legend: {
            position: 'right',
            labels: {
              padding: 20
            }
          },

          title: {
            display: (title && title.length > 0),
            text: title
          },

          tooltip: {
            callbacks: {
              label: renderChartLabel
            }
          }
        }
      }
    }
  }
}

export const CHART_COLORS = {
  green: '#3fcf95',
  darkgray: "#5b5b5b",
  lime: '#9bff54',
  blue: '#4707ec',
  yellow: '#f0c500',
  grey: '#8e8e8e',
  purple: '#b900f0)',
  orange: '#ee8900'
};


function buildChartData(poll) {
  let labels = [];
  let data = [];
  let colors = [];

  for (let i=0; i < poll.options.length; i++) {
    let option = poll.options[i];
    labels.push(option.chart_label);
    data.push(option.votes_count);
    colors.push(Object.values(CHART_COLORS)[i]);
  }

  return {
    labels: labels,
    datasets: [{
      label: ' Votes',
      data: data,
      backgroundColor: colors,
      hoverOffset: 0
    }]
  };
}

function renderChartLabel(context) {
  let total = context.dataset.data.reduce((acc, v) => acc + v, 0);
  let current = context.dataset.data[context.dataIndex];
  let percentage = Math.floor(((current/total) * 100) + 0.5);
  return `${context.dataset.label}: ${percentage}%`;
}

import React, {Component} from 'react';
import Form from './form';
import Request from '../request';

export default class PollForm extends Component {
  constructor(props) {
    super(props);
    this.state = {poll_option: props.poll_option};

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(poll_option) {
    this.setState({poll_option});
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {poll_option} = this.state;
    let {poll} = this.props;

    let url = `/admin/polls/${poll.id}/poll_options`;
    let method;


    if (poll_option.id) {
      url += "/" + poll_option.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    Request(method, url, poll_option, {multipart: true, root: 'poll_option'})
      .then(poll_option => {
        window.location = '/admin/polls/' + poll.id;
      })
      .catch(({errors}) => {
        this.setState({errors, submitting: false});
      });
  }

  fields() {
    let {poll_option} = this.state;

    return [
      {name: 'chart_label', required: true},
      {name: 'text', type: 'markdown', required: true, hint: 'You can use markdown.'},
      {name: 'declarative_statement', type: 'text'},
      {name: 'original_image', type: 'file', required: !poll_option.id}
    ];
  }

  render() {
    let {poll_option, submitting, errors} = this.state;
    return (
      <div>
        <Form model={poll_option} model_name="Poll Option" fields={this.fields()} onChange={this.onChange} onSubmit={this.onSubmit} submitting={submitting} errors={errors} />
      </div>
    );
  }
}

import React, {Component} from 'react';
import Markdown from './markdown';
import Request from '../request';
import Loading from './loading';

export default class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {loading: true};
  }

  componentDidMount() {
    this.fetchComments();
  }

  fetchComments() {
    this.setState({loading: true});
    let {poll} = this.props;
    let url = `/polls/${poll.id}/comments`;
    Request('GET', url).then(({comments, voted_comments}) => {
      this.setState({comments, voted_comments, loading: false});
    });
  }

  voteComment(comment) {
    if (this.state.voting) return;

    let {poll} = this.props;
    this.setState({voting: true});

    let url = `/polls/${poll.id}/comments/${comment.id}/vote`;
    Request('PUT', url)
      .then(comment => {
        let {comments, voted_comments} = this.state;
        let index = comments.findIndex(c => c.id === comment.id);
        comments[index] = comment;
        voted_comments.push(comment.id);
        this.setState({comments, voted_comments, voting: false});
      })
      .catch(({errors}) => {
        console.log("Error voting");
      });
  }


  undoVoteComment(comment) {
    if (this.state.voting) return;

    let {poll} = this.props;
    this.setState({voting: true});

    let url = `/polls/${poll.id}/comments/${comment.id}/undo_vote`;
    Request('PUT', url)
      .then(comment => {
        let {comments, voted_comments} = this.state;
        let index = comments.findIndex(c => c.id === comment.id);
        comments[index] = comment;
        let voted_comment_index = voted_comments.findIndex(id => id === comment.id);
        voted_comments.splice(voted_comment_index, 1);
        this.setState({comments, voted_comments, voting: false});
      })
      .catch(({errors}) => {
        console.log("Error voting");
      });
  }

  renderLoading() {
    return <Loading />
  }

  renderComment(comment) {
    let {user_id, poll} = this.props;
    let profile_link, user_name;
    let link_class_name = 'comment-author-link';
    let voted_option = poll.options.find(o => o.id === comment.poll_option_id);

    if (user_id === comment.user_id) {
      profile_link = '/profile';
      user_name = 'YOU';
      link_class_name += ' self';
    } else {
      profile_link = '/user_profiles/' + comment.user_id;
      user_name = comment.user_name.length > 24 ? comment.user_name.slice(0, 24) + '...' : comment.user_name;
    }
    return(
      <div key={comment.id} className="comment d-flex align-items-start">
        <div className="votes">
          {this.renderVoteCommentButton(comment)}
          {comment.votes_count}&nbsp;
          {!poll.is_open ? <span className="upvote-label-text">{comment.votes_count === 1 ? 'upvote' : 'upvotes' }</span> : ''}
        </div>
        <div className="comment-content">
          <span className="commment-vote-label"><i className="fa fa-check-circle"></i> <b>{voted_option.chart_label}</b></span>
          <Markdown text={comment.text} />
          <a href={profile_link} className={link_class_name}>@{user_name}</a>
          {this.renderCommentShareBtn(comment)}
        </div>
      </div>
    )
  }

  renderCommentShareBtn(comment) {
    let {poll} = this.props;
    let truncatedTitle = poll.question.length > 100 ? poll.question.substring(0, 97) + "..." : poll.question;

    let truncatedText = comment.text.length > 100 ? comment.text.substring(0, 97) + "..." : comment.text;
    let text = `${truncatedText} %0A%0A ${truncatedTitle} %0A@peoplestakenba %0A%0A`;
    let url = window.location.href;
    let link = `https://twitter.com/intent/tweet?text=${text}&url=${url}`

    return (
      <div className="mt-3">
        <a href={link} target="_blank"><b>Post on X</b></a>
      </div>
    )
  }


  renderVoteCommentButton(comment) {
    let {poll, user_id} = this.props;
    if (!poll.is_open) return null;

    let {voted_comments} = this.state;
    let is_voted_comment = voted_comments && voted_comments.findIndex(id => id === comment.id) !== -1

    if (is_voted_comment) {
      return <button onClick={e => this.undoVoteComment(comment)} className="comment-upvote-button"><span className="fa fa-arrow-up"></span></button>
    } else {
      return <button onClick={e => this.voteComment(comment)} className="comment-upvote-button not-upvoted"><span className="fa fa-arrow-up"></span></button>
    }
  }


  renderNoComments() {
    return (
      <div id="comments-list">
        This poll has no comments.
      </div>
    );
  }

  renderCommentsHeader() {
    let {poll} = this.props;
    let headlineText, subText;
    if (poll.is_open) {
      headlineText = "Upvote the Best Takes!"
      subText = "Upvoting ends when the poll ends. Refresh to see the latest."
    } else {
      headlineText = "Top Takes"
      subText = "This poll is closed. No more comments. No more upvotes. Time will tell."
    }
    return (
      <div>
        <h4><b>{headlineText}</b></h4>
        <p>{subText}</p>
      </div>
    )
  }

  renderComments() {
    let {comments, loading} = this.state;
    if (loading) return this.renderLoading();
    if (!comments || comments.length === 0) return this.renderNoComments();
    return (
      <div id="comments-list">
        {comments.map(c => this.renderComment(c))}
      </div>
    );
  }


  render() {
    return (
      <div id="comments">
        {this.renderCommentsHeader()}
        {this.renderComments()}
      </div>
    )
  }

}

import React, {Component} from 'react';

export default class Loading extends Component {
  renderText(text) {
    return <h2>{text}</h2>
  }

  renderSpinner() {
    let size = this.props.size || 80;
    return <i className="fa fa-spinner fa-spin" style={{fontSize: size}}/>
  }

  render() {
    let {containerStyle, text} = this.props;
    if (!text || text.length === 0) return this.renderSpinner();
    return(
      <div className={containerStyle}>
        {this.renderText(text)}
        {this.renderSpinner()}
      </div>
    );
  }
}

import React, {Component} from 'react';
import DatePicker from 'react-datepicker';
//import moment from 'moment';
import {parseISO} from 'date-fns';

import "react-datepicker/dist/react-datepicker.css";

export default class DatePickerWrapper extends Component {

  onChange(value) {
    console.log("changed", value);
    let {name, onChange} = this.props;
    let target = {name, value}
    onChange({target})
  }

  render() {
    let {value, id, dateFormat, minDate, maxDate, showTimeInput, showTimeSelect} = this.props;
    // console.log("value", value);
    if (typeof(value) === 'string' && value.length > 0) {
    //   let datetime = value;// + " 00:00:00";
    //   // Fix issue with safari
    //   datetime = datetime.replaceAll('-', '/');
      //   value = new Date(datetime);
      value = parseISO(value);
     }

    return <DatePicker dateFormat={dateFormat} id={id} selected={value} className="form-control" onChange={d => this.onChange(d)} autoComplete="off" minDate={minDate} maxDate={maxDate} showTimeInput={showTimeInput} showTimeSelect={showTimeSelect} />
  }
}

import React, {Component} from 'react';
import Form from './form';
import Request from '../request';

export default class PollForm extends Component {
  constructor(props) {
    super(props);
    this.state = {poll: props.poll};

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(poll) {
    this.setState({poll});
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({submitting: true});
    let {poll} = this.state;

    let url = "/admin/polls";
    let method;


    if (poll.id) {
      url += "/" + poll.id;
      method = "PUT"
    } else {
      method = "POST"
    }

    Request(method, url, poll, {multipart: true, root: 'poll'})
      .then(poll => {
        window.location = '/admin/polls/' + poll.id;
      })
      .catch(({errors}) => {
        this.setState({errors, submitting: false});
      });
  }

  fields() {
    return [
      {name: 'url', required: true, hint: 'Must be unique. Do not change once active unless you have a very good reason.'},
      {name: 'question', type: 'markdown', required: true, hint: 'You can use markdown.'},
      {name: 'description', type: 'markdown'},
      {name: 'start_date', type: 'datetime', required: true},
      {name: 'end_date', type: 'datetime', required: true},
      {name: 'original_image', type: 'file'}
    ];
  }

  render() {
    let {poll, submitting, errors} = this.state;
    return (
      <div>
        <Form model={poll} model_name="Poll" fields={this.fields()} onChange={this.onChange} onSubmit={this.onSubmit} submitting={submitting} errors={errors} />
      </div>
    );
  }
}
